#container {
  margin: 40px auto;
  margin-top: 0;
  overflow-x: hidden;
}

.little_pic .athlete_picture-container {
  width: 60px;
  height: 60px;
  background-color: #eee;
  border-radius: 50%;
  border: 3px solid #ccc;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.little_pic .athlete_picture-container--gold {
  border: 3px solid #e8b318;
}
.little_pic .athlete_picture-container1 {
  border: 3px solid #e8b318;
}
.little_pic .athlete_picture-container--silver {
  border: 3px solid #a9a9a9;
}
.little_pic .athlete_picture-container2 {
  border: 3px solid #a9a9a9;
}
.little_pic .athlete_picture-container--bronze {
  border: 3px solid #cd7f32;
}
.little_pic .athlete_picture-container3 {
  border: 3px solid #cd7f32;
}
.little_pic .athlete_picture-container--red {
  border: 3px solid #cf022b;
}
.little_pic .athlete_picture-container--grey {
  border: 3px solid #999999;
}
.little_pic .athlete_picture-container--empty {
  background-image: linear-gradient(
    45deg,
    #ffffff 25%,
    #eeeeee 25%,
    #eeeeee 50%,
    #ffffff 50%,
    #ffffff 75%,
    #eeeeee 75%,
    #eeeeee 100%
  );
  background-size: 8.49px 8.49px;
}

.little_pic .athlete_picture {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: auto;
  display: inline-block;
}

.athlete_picture-container {
  width: 80px;
  height: 80px;
  background-color: #eee;
  border-radius: 50%;
  border: 3px solid #ccc;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.athlete_picture-container--gold {
  border: 4px solid #e8b318;
}
.athlete_picture-container1 {
  border: 4px solid #e8b318;
}
.athlete_picture-container--silver {
  border: 4px solid #a9a9a9;
}
.athlete_picture-container2 {
  border: 4px solid #a9a9a9;
}
.athlete_picture-container--bronze {
  border: 4px solid #cd7f32;
}
.athlete_picture-container3 {
  border: 4px solid #cd7f32;
}
.athlete_picture-container--red {
  border: 4px solid #cf022b;
}
.athlete_picture-container--grey {
  border: 4px solid #999999;
}
.athlete_picture-container--empty {
  display: inline-block;
  background-image: linear-gradient(
    45deg,
    #ffffff 25%,
    #eeeeee 25%,
    #eeeeee 50%,
    #ffffff 50%,
    #ffffff 75%,
    #eeeeee 75%,
    #eeeeee 100%
  );
  background-size: 8.49px 8.49px;
}

.athlete_picture {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -45px;
  width: 90px;
  height: auto;
  display: inline-block;
}

.athlete_picture_big-container {
  width: 150px;
  height: 150px;
  background-color: #eee;
  border-radius: 50%;
  border: 4px solid #ccc;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.athlete_picture_big-container--empty {
  background-image: linear-gradient(
    45deg,
    #ffffff 25%,
    #eeeeee 25%,
    #eeeeee 50%,
    #ffffff 50%,
    #ffffff 75%,
    #eeeeee 75%,
    #eeeeee 100%
  );
  background-size: 8.49px 8.49px;
}

.athlete_picture_big {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -90px;
  width: 180px;
  height: auto;
  display: inline-block;
}

.score_points {
  padding: 10px;
  font-size: 1.5em;
  color: #fff;
  background-color: #999;
  border-radius: 10px;
  font-weight: 700;
}

.score_points_rank {
  font-size: 1.5em;
}
.score_points_time {
  font-size: 2.25em;
}

.score_place {
  height: 60px;
  width: 60px;
  border: 3px solid #999;
  border-radius: 50%;
  font-size: 1.5em;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  font-weight: bod;
}
.score_place--gold {
  border: 3px solid #e8b318;
}
.score_place1 {
  border: 3px solid #e8b318;
}
.score_place--red {
  border: 3px solid #cf022b;
}
.score_place--silver {
  border: 3px solid #777777;
}
.score_place2 {
  border: 3px solid #777777;
}
.score_place--bronze {
  border: 3px solid #cd7f32;
}
.score_place3 {
  border: 3px solid #cd7f32;
}
.score_place--grey {
  border: 3px solid #333333;
}
.athlete-flag {
  border-radius: 10px;
}

.float-left-filter {
  font-size: 0.75em;
  margin-left: 20px;
  float: left;
}
.float-left-back {
  margin-left: 20px;
  font-size: 0.925em;
  float: left;
}
.float-left-back:hover {
  cursor: pointer;
}
.float-left-filter:hover {
  color: #fff;
  cursor: pointer;
}
.float-left-filter-men {
  color: #739598;
}
.float-left {
  font-size: 0.825em;
}
.AbsoluteSlider span {
  margin-right: 10px;
}
.AbsoluteSlider {
  position: absolute;
  top: 1.5em;
  right: 1em;
  font-size: 0.925em;
  display: flex;
}
.NbRacesInfos {
  position: absolute;
  top: 1.5em;
  left: 1em;
  font-size: 0.925em;
  display: flex;
}

.LoaderContainer {
  min-height: 140px;
}
.ranking-container {
  overflow-y: auto;
  max-height: 48vh;
  max-height: 440px;
  border: 1px solid #ccc;
}
.ranking-container2 {
  overflow: hidden;
  height: 48vh;
  height: 440px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ranking-container3 {
  overflow-y: auto;
  max-height: 48vh;
  max-height: 530px;
  border: 1px solid #ccc;
}
.history-container {
  height: auto;
}

.progress_bar-shooting {
  min-width: 400px;
}

.ui.header.main_header {
  padding: 10px 0;
  background-color: #333333;
  margin-bottom: 20px;
  color: #fff;
  position: relative;
}

.ui.header > .image:not(.icon),
.ui.header > img.logo_img {
  width: 260px;
  height: auto;
  margin: 0 20px 0 0;
}
img.logo_img:hover {
  cursor: pointer;
}
.ui.header > .ui.dropdown {
  font-size: 0.75em;
  margin: 0 5px;
}
.ui.header > .ui.dropdown:focus,
.ui.header > .ui.dropdown:active {
  outline: 0;
}
.ui.header.main_header:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333333;
  margin-left: -10px;
}

.ui.header.gender_title {
  padding: 5px 0;
  background-color: #cccccc;
  margin-bottom: 20px;
  color: #fff;
  position: relative;
}

.ui.header.gender_title::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  /*clear: both;*/
  margin-left: -10px;
}

.ui.header.gender_title-male {
  background-color: #b8ced0;
}

.ui.header.gender_title-male::after {
  border-top: 10px solid #b8ced0;
}

.ui.header.gender_title-female {
  background-color: #ecc9d7;
}

.ui.header.gender_title-female::after {
  border-top: 10px solid #ecc9d7;
}
.highcharts_content {
  height: auto;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.athlete_filter-dropdown {
  width: 50%;
}
/*.filters-container {
  padding: 20px;
}*/
.filters_area_men {
  background-color: #eaecec;
  margin-top: 10px;
}
.filters_area_men_single {
  background-color: #eaecec;
  margin-top: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.filters_area_women {
  background-color: #f1eaed;
  margin-top: 10px;
}
.filters_legend {
  text-align: center;
  padding-top: 8px;
}
.filters_dropdown {
  text-align: center;
}
.text_center {
  text-align: center;
}
.trophy {
  font-size: 1.5em;
}
.trophy_gold {
  color: #e8b318;
}
.trophy_silver {
  color: #a9a9a9;
}
.trophy_bronze {
  color: #cd7f32;
}
.flowers_ceremony {
  font-size: 1.5em;
}
.ui.table td.center_text,
.ui.table th.center_text {
  text-align: center;
}
.u25_span {
  padding: 2px 5px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  background-color: #8aa7b5;
  font-size: 0.75em;
  margin-left: 15px;
}
/*sidebar athlete card*/
.athlete_clickable:hover,
.ui.striped.table tbody tr.athlete_clickable:nth-child(2n):hover {
  cursor: pointer;
  background-color: #eee;
}
.sidebar_athlete {
  border-right: 10px solid #fff;
  background-color: #eee;
  padding: 20px;
}

.ui.header.athlete_card_name {
  margin-top: 10px;
  margin-bottom: -20px;
}
.ui.table.athlete_card_nationality {
  border: 0;
}
.athlete_card_age {
  font-size: 1.125em;
  color: #777;
  margin-bottom: 15px;
}

.close_athlete_modale {
  float: right;
  font-size: 1.25em;
}
.close_athlete_modale:hover {
  color: #999999;
  cursor: pointer;
}

.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.race_list-icon {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: right;
  width: 100%;
  display: inline-block;
}

.ui.dropdown.race_list-dropdown {
  font-size: 1.375em;
}

.race_list-icon {
  line-height: 2.25em;
  margin-bottom: 5px;
}

.race_list-icon .float-left {
  font-size: 0.75em;
}

.grid-center {
  text-align: center;
  font-size: 2.25em;
}

.grid-center-icon .ui.grey.label,
.grid-center-icon .ui.grey.labels .label {
  background-color: #999999 !important;
  border-color: #999999 !important;
  color: #fff !important;
}

.grid-center-icon2 .ui.grey.label,
.grid-center-icon2 .ui.grey.labels .label {
  background-color: #cccccc !important;
  border-color: #cccccc !important;
  color: #fff !important;
}

.grid-center-icon,
.grid-center-icon2 {
  text-align: center;
}
.grid-center-icon-weather {
  font-size: 1.125em;
  line-height: 1.25em;
}
i.icon-weather-semantic {
  font-size: 0.75em;
}
.athlete-min-raceresult {
  display: inline-block;
}
.race-ranking-name {
  font-size: 0.5em;
  margin-top: 10px;
  text-align: center;
}
.click_raceResult:hover {
  cursor: pointer;
  background-color: #eee;
}
.race_list-plus {
  font-size: 2.25em;
  line-height: 2.25em;
  text-align: right;
}
.race_list-plus i:hover {
  cursor: pointer;
  color: #999;
}
.ui.sortable.table thead th.no_order:hover {
  cursor: default;
}
i.icon.eraser-styles {
  display: inline-block;
  margin-left: 30px;
  font-size: 1.125em;
}
i.icon.eraser-styles:hover {
  cursor: pointer;
  color: #999;
}
.up_score {
  color: #006600;
  font-size: 2.25em;
}
.up_score-score {
  font-size: 0.5em;
  display: inline-block;
  margin-left: -10px;
}
.down_score {
  color: #cf022b;
  font-size: 2.25em;
}
.down_score-score {
  font-size: 0.5em;
  display: inline-block;
  margin-left: -10px;
}
.equal_score {
  font-size: 2.25em;
  color: #999;
}

.ui[class*="very wide"].left.sidebar,
.ui[class*="very wide"].right.sidebar {
  width: 650px;
}

.ui.visible[class*="very wide"].left.sidebar ~ .fixed,
.ui.visible[class*="very wide"].left.sidebar ~ .pusher {
  -webkit-transform: translate3d(650px, 0, 0);
  transform: translate3d(650px, 0, 0);
}
.athlete_card_skills {
  padding: 0;
}
.select_discipline {
  margin-bottom: 20px;
}
.greenArrow {
  color: #21ba45;
}
.greyArrow {
  color: #888;
}
.redArrow {
  color: #db2828;
}
.custom_container {
  width: 33%;
  margin: 0 auto;
}
.width100centered .trophy {
  font-size: 0.75em;
  margin-top: 10px;
  margin-right: 0;
}
.overpointer:hover {
  cursor: pointer;
}
.green {
  background-color: rgba(11, 87, 29, 0.2);
}
.orange {
  background-color: rgba(153, 82, 20, 0.2);
}

/* Responsive */
@media only screen and (max-width: 1740px) {
  .custom_container {
    width: 40%;
  }
}

@media only screen and (max-width: 1500px) {
  .custom_container {
    width: 45%;
  }
  .AbsoluteSlider {
    position: inherit;
    display: inherit;
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 1380px) {
  ui.column.grid > [class*="singlerace_header_label"].column,
  .ui.grid > .column.row > [class*="singlerace_header_label"].column,
  .ui.grid > .row > [class*="singlerace_header_label"].column,
  .ui.grid > [class*="singlerace_header_label"].column {
    width: 25% !important;
  }
  ui.column.grid > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .column.row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > [class*="singlerace_header_dropdown"].column {
    width: 50% !important;
  }
  .score_place {
    height: 40px;
    width: 40px;
    line-height: 34px;
    font-size: 1em;
  }
  .little_pic .athlete_picture-container {
    width: 40px;
    height: 40px;
  }

  .little_pic .athlete_picture {
    margin-left: -20px;
    width: 40px;
  }
}

@media only screen and (max-width: 1320px) {
  .custom_container {
    width: 60%;
  }
  .score_points_time {
    font-size: 2em;
  }
  .ui.table.singlerace_full_table {
    font-size: 0.825em;
  }
  .ui.table.ui.table.singlerace_full_table h3.ui.header {
    font-size: 1.5em;
  }
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    width: 100% !important;
    margin: 0 0 !important;
    box-shadow: none !important;
    padding: 1rem 1rem !important;
  }
  .AbsoluteSlider {
    position: absolute;
    top: 1.5em;
    right: 1em;
    font-size: 0.925em;
    display: -webkit-flex;
    display: flex;
    width: inherit;
    padding: 0;
    margin: 0;
    border: none;
  }
  .ranking-container {
    max-height: 48vh;
  }
  .ranking-container3 {
    max-height: 48vh;
  }
  .ranking-container2 {
    height: 48vh;
  }
}
.twitter_content {
  font-size: 2em;
}
.minHeightContent {
  height: 48vh;
}
.ui.segment.noborder {
  border: none;
  box-shadow: none;
}
div.noborder {
  border: none;
  box-shadow: none;
}
.ui.segment.marginplus {
  margin-bottom: 20px;
}
.ui.segment.marginless {
  margin-top: 0;
}
.name_container {
  font-size: 0.75em;
  min-width: 50px;
  position: relative;
}
.ui.table thead th.centered_cell,
.ui.table tbody td.centered_cell {
  text-align: center;
}
.ui.table tbody td.big_number {
  font-size: 1.5em;
  font-weight: bold;
}
.width100centered {
  width: 150px;
  text-align: center;
  display: inline-block;
  margin-top: 5px;
}
.gold {
  color: #e8b318;
}
.red {
  color: #cf022b;
}
.trophyfont {
  font-size: 1.25em;
}
.fullheight {
  min-height: 100vh;
}
.increaseIcon {
  font-size: 1.5em;
}
.man_birth {
  color: #5cc4f2;
}
.woman_birth {
  color: #f576ab;
}
.marginleftright {
  margin-left: 20px;
  margin-right: 20px;
}

.ui.massive.label,
.ui.massive.labels .label {
  font-size: 3em;
}

@media only screen and (max-width: 1180px) {
  .ui.grid > [class*="singlerace_min_grid"].row > .column {
    width: 33.3333333% !important;
    margin-bottom: 5px;
  }
  .custom_container {
    width: 90%;
  }
}

@media only screen and (max-width: 1040px) {
  ui.column.grid > [class*="race_list-plus"].column,
  .ui.grid > .column.row > [class*="race_list-plus"].column,
  .ui.grid > .row > [class*="race_list-plus"].column,
  .ui.grid > [class*="race_list-plus"].column {
    width: 12.5% !important;
  }
  ui.column.grid > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .column.row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > [class*="singlerace_header_dropdown"].column {
    width: 62.5% !important;
  }
  .singlerace_icons_grid .grid-center {
    font-size: 1.5em;
  }
  h2.ui.header {
    font-size: 1.25em;
  }
}
@media only screen and (max-width: 920px) {
  .ui.table.singlerace_full_table {
    font-size: 0.7em;
  }
  .ui.table.ui.table.singlerace_full_table h3.ui.header {
    font-size: 1.375em;
  }
  .athlete_clickable img {
    width: 30px;
  }
  .score_points_time {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 820px) {
  .race_list-icon {
    font-size: 16px;
    line-height: 44px;
  }
  ui.column.grid > [class*="singlerace_header_label"].column,
  .ui.grid > .column.row > [class*="singlerace_header_label"].column,
  .ui.grid > .row > [class*="singlerace_header_label"].column,
  .ui.grid > [class*="singlerace_header_label"].column {
    width: 18.75% !important;
  }
  ui.column.grid > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .column.row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > [class*="singlerace_header_dropdown"].column {
    width: 68.75% !important;
  }
  .progress_bar-shooting {
    min-width: 300px;
  }
}

@media only screen and (max-width: 780px) {
  ui.column.grid > [class*="singlerace_header_label"].column,
  .ui.grid > .column.row > [class*="singlerace_header_label"].column,
  .ui.grid > .row > [class*="singlerace_header_label"].column,
  .ui.grid > [class*="singlerace_header_label"].column {
    width: 100% !important;
    text-align: left;
  }
  ui.column.grid > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .column.row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > .row > [class*="singlerace_header_dropdown"].column,
  .ui.grid > [class*="singlerace_header_dropdown"].column {
    width: 87.5% !important;
  }
  .race_list-icon {
    text-align: center;
  }
  .ui.grid > [class*="singlerace_min_grid"].row > .column {
    width: 50% !important;
  }
  .ui[class*="very wide"].left.sidebar,
  .ui[class*="very wide"].right.sidebar {
    width: 100%;
  }

  .ui.visible[class*="very wide"].left.sidebar ~ .fixed,
  .ui.visible[class*="very wide"].left.sidebar ~ .pusher {
    -webkit-transform: translate3d(100% 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .singlerace_icons_grid .grid-center {
    font-size: 1.125em;
  }
  .ui.dropdown.race_list-dropdown {
    font-size: 1em;
  }
  .race_list-plus {
    font-size: 2em;
    line-height: 1.5em;
    text-align: right;
  }
  .ui.header.main_header {
    margin-bottom: 20px;
  }
  .ui.table.ui.table.singlerace_full_table h3.ui.header {
    font-size: 1.28571429rem;
  }
  .ui.table.singlerace_full_table {
    font-size: 1em;
  }
  /*.singlerace_full_table .score_place {
    height: 60px;
    width: 60px;
    line-height: 50px;
  }*/
  /*.ranking-container {
    overflow-y: auto;
    max-height: initial;
    height: auto;
  }*/

  .ui.grid > .filters_home.filters_home.row > .column,
  .ui.filters_home.grid > .column.grid > .column,
  .ui.filters_home.grid > .column.row > .column,
  .ui.filters_home.grid > .column:not(.row),
  .ui.filters_home.grid > .row > .column,
  .ui.filters_home.grid > .row > .wide.column,
  .ui.filters_home.grid > .wide.column {
    width: 100% !important;
    margin: 0 0 !important;
    box-shadow: none !important;
    padding: 5px 1rem !important;
  }
  .ui.grid > .column.margin_grid:not(.row),
  .ui.grid > .row > .column.margin_grid {
    display: none;
  }
  /*.hide_wdr {
    display: none;
  }*/
  .ui.grid > .row.filters_area_men {
    padding-top: 5px;
  }
  .progress_bar-shooting {
    min-width: inherit;
  }
  .AbsoluteSlider {
    position: inherit;
    display: inherit;
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  h2.ui.header.ranking_title {
    margin-left: 60px;
    margin-right: 60px;
  }
  .athlete_card_skills {
    margin-left: 1em;
    margin-right: 1em;
  }
  .custom_container {
    width: 94%;
  }
}

@media only screen and (max-width: 680px) {
  .ui.segment {
    position: relative;
    background: #fff;
    box-shadow: none;
    margin: 1rem 0;
    padding: 1em 0;
    border-radius: 0.28571429rem;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }
  ui.column.grid > [class*="race_list-plus"].column,
  .ui.grid > .column.row > [class*="race_list-plus"].column,
  .ui.grid > .row > [class*="race_list-plus"].column,
  .ui.grid > [class*="race_list-plus"].column {
    margin-left: -10px;
  }
  .ui.header.main_header {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 1rem 5px !important;
  }
  .ranking-container {
    border: none;
  }
  .ui.table {
    margin: 0;
  }
  .singlerace_min_grid img.ctry_flag {
    width: 30px;
  }
  .athlete_clickable img {
    width: 20px;
  }
  .ranking_results_table h3.ui.header {
    font-size: 1em;
  }
  .score_points_time {
    font-size: 1.25em;
  }
  .score_place {
    height: 30px;
    width: 30px;
    line-height: 25px;
    font-size: 0.75em;
  }
  .little_pic .athlete_picture-container {
    width: 30px;
    height: 30px;
  }

  .little_pic .athlete_picture {
    margin-left: -15px;
    width: 30px;
  }
  .wi.grid-center-icon-weather {
    font-size: 1.5em;
    line-height: 1.5em;
  }
  .ui.grid.singlerace_header_grid > .row {
    padding-top: 0;
  }
  .score {
    font-size: 1.5em;
  }
  .score i {
    font-size: 0.75em;
    margin-right: 5px;
  }
  .athlete_card_age {
    margin-top: 10px;
  }
  .width100centered .trophy {
    display: inline-block;
    margin-top: 2px;
  }
}
@media only screen and (max-width: 530px) {
  .athlete_picture_big-container {
    width: 100px;
    height: 100px;
    background-color: #eee;
    border-radius: 50%;
    border: 4px solid #ccc;
    overflow: hidden;
    text-align: center;
    position: relative;
  }
  .width100centered {
    width: 100px;
  }
  .athlete_picture_big {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    height: auto;
    display: inline-block;
  }
}

/*imported*/
.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 1000000;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -30px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
